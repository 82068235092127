.header-tabs {
    display: flex;
    gap: 16px;
   
  
  }
  .header-tabs a, button {
    text-decoration: solid;
    color: var(--white)
  }


  .header-tabs .active {
    color: var(--primary)
  }

  .rule-tab {
    background: transparent;
    border: none;
    text-decoration: solid;
    color: var(--white);
    font-size: 1rem;
    font-family: 'Assistant';
  }
  