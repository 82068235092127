.event-card {
  border: 1px solid var(--secondary);
  padding: 16px;
  width: 200px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  align-items: center;
  content-visibility: auto;
  contain-intrinsic-size: 270px;
}
.card-selected button {
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--primary);
}
.event-time {
  text-align: center;
  margin-bottom: 16px;
  gap: 6px;
  display: flex;
  flex-direction: column;
}

.event-time div {
  font-size: 0.8rem;
  font-weight: bold;
}
.card-title {
  font-weight: bold;
}
.card-btn {
  padding: 16px;
  background-color: var(--primary);
  color: var(--white);
  border: none;
  border-radius: 8px;
  width: 100%;
}

.active:active {
  background-color: var(--primary200);
}

.disable-btn {
  background-color: var(--secondary);
  cursor: not-allowed;
}

.card-title {
  font-size: 1rem;
}


time {
  font-size: 0.9rem;
}