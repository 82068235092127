.sport-header {
  border-bottom: 1px solid var(--secondary);
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--white);
  background-color: #333333;
}

.header-profile {
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-img {
  width: 40px;
  margin-right: 16px;
  height: 40px;
  background: var(--primary);
  border-radius: 100%;
}
.profile-name {
  margin: 0;
}

.allevents-container {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  padding: 0;
  padding: 16px;
  justify-content: center;
}

.page-error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  gap: 16px;
}

.allevent,
.retry-button {
  padding: 16px;
  background-color: var(--primary);
  border: none;
  width: max-content;
  position: absolute;
  inset: 0;
  margin: auto;
  border-radius: 8px;
  height: 48px;
}

.allevent a {
  color: var(--white);
  text-decoration: none;
}

@media screen and (max-width: 480px) {
  .allevents-container {
    justify-content: center;
  }
}
