a,
button {
  cursor: pointer;
}

.sports-event-home {
  color: var(--white);
  padding: 32px;
  display: flex;
  background: rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
  height: 90vh;
}
.sports-event-home h1 {
  margin: 0;
  font-size: 5.5rem;
  text-align: center;
}
@font-face {
  font-family: "Assistant";
  font-display: swap;
  font-weight: 400;
  src: local("Assistant"),
    url("./font/Assistant-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Assistant";
  font-display: swap;
  font-weight: 700;
  src: local("Assistant"), url("./font/Assistant-Bold.ttf") format("truetype");
}

body {
  font-family: "Assistant";
}

:root {
  font-family: Assistant;
  --secondary: #e9e9eb;
  --primary100: #ffb43f33;
  --primary200: #ffb43f99;
  --grey200: #e9e9eb;
  --grey300: #d4d5d8;
  --primary: #ffb43fcc;
  --grey800: #282c3f;
  --white: #fff;
  --orange100: #be4a2a;
  --green100: #ddffdd;
  --yellow100: #ffaa01;
}
